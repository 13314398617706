import React from "react";

import { Button } from "reactstrap";
import { signOutNearWallet } from "../../near-api";
import { Wallet } from './../../near/near-wallet';



const Login = () => {
  
  // When creating the wallet you can choose to create an access key, so the user
  // can skip signing non-payable methods when interacting with the contract
  //const wallet = new Wallet({ createAccessKeyFor: "dev-1666176534370-56189442712103" })

  // Abstract the logic of interacting with the contract to simplify your project
  // const counter = new Counter({ contractId: process.env.CONTRACT_NAME, walletToUse: wallet });

  const wallet = new Wallet({ createAccessKeyFor: process.env.CONTRACT_NAME })

  wallet.startUp();

  if (!window.walletConnection.isSignedIn()) {

    return (
      <>
        <Button color="primary" className="btn-success btn-label right" onClick={() => wallet.signIn()}>
          <i className="bx bx-wallet label-icon align-middle fs-16 ms-2"></i>
          Sign In
        </Button>
      </>
    );
  }
  return (
    // use React Fragment, <>, to avoid wrapping elements in unnecessary divs
    <>
        <button 
          className="btn btn-success" 
          style={{backgroundColor: "grey", border: "none"}} 
          onClick={signOutNearWallet}>
          Sign out
        </button>
    </>
  );
};
export default Login;
